import { decode } from "./tools/decoder";
import { generateSocketAddress } from "./tools/addressGenerator";
const { v4: uuidv4 } = require('uuid');
import { point } from "@turf/turf";
import { getLiveLightningAnimation } from "./layerstyle/layerstyle";

let ws;

// Functionality to initiate the lightning websocket
export const initiateLightningSocket = (map, lightningGeoJson) => {

    // Create the AudioContext
    let audioContext = null;
    let audioBuffer = null;
    let source = null;


    // Load the audio file
    const loadAudio = () => {
        fetch('https://weatherportal.eu/assets/sounds/tick1.wav')
            .then(response => response.arrayBuffer())
            .then(data => audioContext.decodeAudioData(data))
            .then(buffer => {
                audioBuffer = buffer;
            })
            .catch(error => console.error('Error loading audio file:', error));
    };

    // Function to play the sound
    const playSound = () => {
        if (!audioContext) {
            console.error('AudioContext not initialized.');
            return;
        }

        // Ensure the AudioContext is resumed
        if (audioContext.state === 'suspended') {
            audioContext.resume().then(() => {
                console.log('AudioContext resumed');
                startPlayback();
            });
        } else {
            startPlayback();
        }
    };

    // Function to start playback
    const startPlayback = () => {
        // Stop the current playback if any
        if (source !== null) {
            source.stop();
        }

        // Create a new buffer source and play the audio
        source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start(0);

        // Reset the source when playback ends
        source.onended = () => {
            source = null;
        };
    };

    // Initialize the AudioContext on a user gesture
    document.addEventListener('mousedown', () => {
        if (!audioContext) {
            audioContext = new (window.AudioContext || window.webkitAudioContext)();
            loadAudio();
        } else if (audioContext.state === 'suspended') {
            audioContext.resume().then(() => {
            });
        }
    });

    if ("WebSocket" in window) {

        const socketAddress = generateSocketAddress();

        const openWebsocket = () => {
            // Open the Blitzortung web socket
            ws = new WebSocket(socketAddress);
            ws.onopen = function () {
                // Send listening domain bounds to Blitzortung
                const gimmeSomething = '{"a": 111}';
                ws.send(gimmeSomething);
            };

            ws.onmessage = function (evt) {
                const soundAudible = window.localStorage.getItem('sound') === "audible";

                let strike = JSON.parse(decode(evt.data));

                const newLightning = point([strike.lon, strike.lat], {time: new Date, timeDiff: 0});
                lightningGeoJson.features.push(newLightning);
                map.getSource('liveLightning').setData(lightningGeoJson);

                const newId = 'strike' + uuidv4();

                let radius = 3;
                let opacity = 1;

                const bounds = map.getBounds();

                if(audioContext) {
                    if(
                        strike.lon <= bounds._ne.lng &&
                        strike.lon >= bounds._sw.lng &&
                        strike.lat <= bounds._ne.lat &&
                        strike.lat >= bounds._sw.lat &&
                        soundAudible &&
                        audioContext.state !== 'suspended'
                    ) {
                        playSound();
                    }
                }


                map.addLayer(getLiveLightningAnimation(newId, newLightning));
                map.setPaintProperty(newId, 'circle-radius', radius * 30);

                setTimeout(() => {
                    map.setPaintProperty(newId, 'circle-stroke-color', `rgba(0,150,255,0)`);
                }, 500);

                setTimeout(() => {
                    map.setPaintProperty(newId, 'circle-opacity', 0);
                }, 500);

                setTimeout(() => {
                    map.setPaintProperty(newId, 'circle-stroke-width', 15 );
                }, 500);

                setTimeout(() => {
                    map.removeLayer(newId);
                    map.removeSource(newId);
                }, 4000);
            };

            ws.onclose = e => {
                initiateLightningSocket(map, lightningGeoJson);
            };
        }

        const closeWebsocket = () => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        }

        openWebsocket()

        map.on('appInBackground', () => {

        })

        map.on('appInForeground', () => {

        })
    } else {
        // The browser doesn't support WebSocket
        alert("WebSocket NOT supported by your Browser!");
    }
};