import { featureCollection, point } from "@turf/turf";
import { calculateTimeDiffForColorization } from "./tools/helpers";
import axios from "../../../async/axiosConfig";


// Fetch Lightnings from backend
export const fetchLightnings = async () => {
    const lightningGeoJson = featureCollection([]);

    let myLightnings = await(axios.get('https://radarapi.supercell.weatherportal.eu/lightnings'));

    const now = new Date;
    const millis = now.getTime();

    myLightnings.data.data.forEach(strike => {
        const timeDiff = millis - strike.TimeStamp;
        const roundedTimeDiff = calculateTimeDiffForColorization(timeDiff);
        const newLightning = point([strike.lon, strike.lat], {timeDiff: parseFloat(roundedTimeDiff)});

        lightningGeoJson.features.push(newLightning);
    });

    return lightningGeoJson;
};


// Fetching clusters from backend
export const fetchClusters = async () => {
    const clusters = await(axios.get('https://radarapi.supercell.weatherportal.eu/clusters'));

    console.log(clusters)

    return clusters.data;
};


// Cleaning live lightnings
export const cleanLiveLightning = async (liveLightning) => {
    const now = new Date;
    const millis = now.getTime();

    liveLightning.features = liveLightning.features.filter(strike => {
        const strikeTime = new Date(strike.properties.time).getTime();
        const nowMin1 = millis - (10 * 60000);

        const timeDiff = strikeTime - nowMin1;

        return timeDiff <= (10 * 60000) && timeDiff >= 0;
    });
};