export const addRadarLayers = async (map, precipVisibility) => {
    await map.addLayer({
        'id': 'radarSites',
        'type': 'symbol',
        'source': 'radarSites',
        'layout': {
            'icon-image': 'radarSite',
            'icon-anchor': 'bottom',
            'icon-offset': [0, 5],
            'icon-allow-overlap': true,
            'visibility': precipVisibility,
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 0.015,  // Size at zoom level 0
                15, 0.10  // Size at zoom level 15
            ]
        },
    });

    // map.addLayer({
    //     id: 'radar-layer',
    //     type: 'raster',
    //     source: 'radar-layer',
    //     paint: {
    //         'raster-fade-duration': 0,
    //         'raster-resampling': 'nearest',
    //         'raster-opacity': 0.65
    //     }
    // }, 'radarSites');
}

