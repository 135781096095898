// Fetching 500mb height contours from backend
import {hourFhrRunMapping} from "./tools/hourFhrRunMapping";
import axios from "../../../async/axiosConfig";


// Function to close a polyline by making the last point the same as the first point
const closePolyline = (polyline) => {
    let closedPolyline = [...polyline];
    if (polyline[0][0] !== polyline[polyline.length - 1][0] || polyline[0][1] !== polyline[polyline.length - 1][1]) {
        closedPolyline.push(polyline[0]);
    }
    return closedPolyline;
};

// Main function to convert LineStrings to Polygons with closed coordinates
const convertToPolygons = (geojson) => {
    const polygonFeatures = geojson.features.map(feature => {
        if (feature.geometry.type === 'LineString') {
            const closedPolygon = closePolyline(feature.geometry.coordinates);
            return {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [closedPolygon]
                },
                properties: feature.properties || {}
            };
        } else {
            return feature;
        }
    });

    return {
        type: 'FeatureCollection',
        features: polygonFeatures
    };
};

export const fetch500MbHeightContours = async () => {

    const currentDate = new Date();
    const currentHour = currentDate.getUTCHours();
    const fhr = hourFhrRunMapping[currentHour].fhr;
    const run = hourFhrRunMapping[currentHour].run;

    const gpm = await (axios.get(`https://charts.supercell.weatherportal.eu/geojson/gfs/${run}/europekml/geopot500/${fhr}`));
    const gpmGeoJson = gpm.data;
    return gpmGeoJson;
};

export const fetchPres2PVUContours = async () => {

    const currentDate = new Date();
    const currentHour = currentDate.getUTCHours();
    const fhr = hourFhrRunMapping[currentHour].fhr;
    const run = hourFhrRunMapping[currentHour].run;

    const slp = await (axios.get(`https://charts.supercell.weatherportal.eu/geojson/gfs/${run}/europekml/pres2pvu/${fhr}`));
    let pres2pvuJSON = slp.data;

    // Add value property from name property
    pres2pvuJSON.features.forEach(feature => {
        feature.properties.value = parseInt(feature.properties.name);
    });

    pres2pvuJSON = convertToPolygons(pres2pvuJSON);

    return pres2pvuJSON;
};

export const fetchMSLPContours = async () => {

    const currentDate = new Date();
    const currentHour = currentDate.getUTCHours();
    const fhr = hourFhrRunMapping[currentHour].fhr;
    const run = hourFhrRunMapping[currentHour].run;

    const slp = await (axios.get(`https://charts.supercell.weatherportal.eu/geojson/gfs/${run}/europekml/mslp/${fhr}`));
    const mslpGeoJson = slp.data;

    return mslpGeoJson;
};

export const fetch500mbWindspeedContours = async () => {
    const currentDate = new Date();
    const currentHour = currentDate.getUTCHours();
    const fhr = hourFhrRunMapping[currentHour].fhr;
    const run = hourFhrRunMapping[currentHour].run;

    const wspeed = await axios.get(`https://charts.supercell.weatherportal.eu/geojson/gfs/${run}/europekml/wspeed500/${fhr}`);
    let wspeedGeoJson = wspeed.data;

    // Add value property from name property
    wspeedGeoJson.features.forEach(feature => {
        feature.properties.value = parseInt(feature.properties.name);
    });

    // Close the polylines in the GeoJSON
    wspeedGeoJson = convertToPolygons(wspeedGeoJson);

    return wspeedGeoJson;
};