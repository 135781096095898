import { addNWPLayers } from "./nwp/layer/layer";
import { addLightningLayers } from "./lightning/layer/layer";
import { addShootingLocationLayers } from "./markers/layer/layer";
import { addRadarLayers } from "./radar/layer/layer";
import { addSatelliteLayers } from "./sat/layer/layer";
import {addObsLayers} from "./obs/layer/layer";

export const addMapLayers = async (map, dayNight, precipVisibility, satVisibility, satWvVisibility, satVisVisibility) => {

    addNWPLayers(map, dayNight);
    addLightningLayers(map, dayNight);
    addSatelliteLayers(map, satVisibility, satWvVisibility, satVisVisibility, dayNight);
    // addShootingLocationLayers(map, dayNight);
    // addObsLayers(map);


    await addRadarLayers(map, precipVisibility);

};