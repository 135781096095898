import { fetch500MbHeightContours, fetch500mbWindspeedContours, fetchMSLPContours, fetchPres2PVUContours } from "../API";

export const generateNWPSource = async map => {
    const gpm = await fetch500MbHeightContours();
    const mslp = await fetchMSLPContours();
    const ws500mb = await fetch500mbWindspeedContours();
    const pres2pvu = await fetchPres2PVUContours();

    const myGpm = map.addSource('gpm', { 'type': 'geojson', 'data': gpm });
    const myMSLP = map.addSource('mslp', { 'type': 'geojson', 'data': mslp });
    const my500mbWS = map.addSource('500mbWS', { 'type': 'geojson', 'data': ws500mb });
    const myPres2PVU = map.addSource('pres2PVU', { 'type': 'geojson', 'data': pres2pvu })
}

