export const createLightningHistoryLayer = dayNight => {
    const colorScale = dayNight === 'day'
        ?
        [
            [0, 'rgba(45,45,45,1)'],
            [20, 'rgba(70,70,70,1)'],
            [40, 'rgba(100,100,100,1)'],
            [60, 'rgba(145,145,145,1)'],
            [80, 'rgba(200,200,200,1)'],
            [100, 'rgba(240,240,240,1)']
        ]
        :
        [
            [0, 'rgba(240,240,240,1)'],
            [20, 'rgba(200,200,200,1)'],
            [40, 'rgba(145,145,145,1)'],
            [60, 'rgba(100,100,100,1)'],
            [80, 'rgba(70,70,70,1)'],
            [100, 'rgba(55,55,55,1)']
        ];

    return {
        'id': 'lightnings',
        'type': 'circle',
        'source': 'points',
        'paint': {
            // Make circles larger as the user zooms from z5 to z8 to z12.
            'circle-radius': {
                'base': 1,
                'stops': [
                    [5, 1],
                    [13, 4]
                ]
            },
            // Color circles by timeDifference expression.
            'circle-color': {
                "property": "timeDiff",
                'stops': colorScale
            }
        },
        'layout': {
            'visibility': window.localStorage.getItem('historyVisibility')
                ? window.localStorage.getItem('historyVisibility')
                : 'visible'
        }
    };
};

export const clusterLayer = {
    'id': 'clusterLayer',
    'type': 'fill',
    'source': 'clusterPolygons',
    'paint': {
        'fill-color': {
            "property": "count",
            'stops': [
                [1, 'rgba(255,255,0,0.2)'],
                [250, 'rgba(255,153,0,0.2)'],
                [500, 'rgba(240,15,15,0.2)'],
                [1000, 'rgba(255,0,255,0.2)'],
                [1500, 'rgba(255,120,255,0.2)'],
            ]
        },
        'fill-opacity': 0.8
    },
    'layout': {
        'visibility': window.localStorage.getItem('polygonVisibility')
            ? window.localStorage.getItem('polygonVisibility')
            : 'visible'
    }
};

export const clusterLayerContours = {
    'id': 'clusterLayerLines',
    'type': 'line',
    'source': 'clusterPolygons',
    'paint': {
        'line-color': {
            "property": "count",
            'stops': [
                [1, 'rgba(255,255,0,2)'],
                [250, 'rgba(255,126,0,2)'],
                [500, 'rgba(240,15,15,2)'],
                [1000, 'rgba(255,0,255,2)'],
                [1500, 'rgba(255,140,255,2)'],
            ]
        },
        'line-width': 3
    },
    'layout': {
        'visibility': window.localStorage.getItem('polylineVisibility')
            ? window.localStorage.getItem('polylineVisibility')
            : 'visible'
    }
};

export const liveLightningMarkers = {
    'id': 'liveLightnings',
    'type': 'circle',
    'source': 'liveLightning',
    'paint': {
        'circle-radius': {
            'base': 1,
            'stops': [
                [5, 2],
                [13, 3]
            ]
        },
        'circle-stroke-width': {
            'base': 1,
            'stops': [
                [5, 2],
                [13, 3]
            ]
        },
        // Make circles larger as the user zooms from z5 to z8 to z12.
        // 'circle-radius': {
        //     'base': 4,
        //     'stops': [
        //         [5, 1],
        //         [20, 3]
        //     ]
        // },
        // Color circles by timeDifference expression.
        'circle-color': 'rgba(255,255,255,1)',
        'circle-stroke-color': 'rgb(0,150,255)'
    },
    'layout': {
        'visibility': window.localStorage.getItem('liveVisibility')
            ? window.localStorage.getItem('liveVisibility')
            : 'visible'
    }
};

export const getLiveLightningAnimation = (newId, newLightning) => {
    return (
        {
            "id": newId,
            "type": "circle",

            // Create the marker
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": [
                        newLightning
                    ]
                }
            },

            // Draw the circle
            "paint": {
                "circle-opacity": 0.9,
                "circle-stroke-width": 3,
                "circle-color": 'rgb(30, 30, 30)',
                "circle-stroke-color": `rgb(0,150,255)`,
                'circle-radius': {
                    'base': 2,
                    'stops': [
                        [5, 2],
                        [13, 5]
                    ]
                },
                "circle-radius-transition": {
                    "duration": 5000
                },
                "circle-opacity-transition": {
                    "duration": 3000
                },
                "circle-stroke-color-transition": {
                    "duration": 2000
                },
                "circle-stroke-width-transition": {
                    "duration": 3000
                }
            },
            "layout": {
                "visibility": window.localStorage.getItem('liveVisibility') === "visible" ? "visible" : "none"
            }
        }
    );
};

