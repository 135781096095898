export const hourFhrRunMapping = {
//  UTC, Model run, Forecast hour
    0: { run: '18', fhr: '6' },
    1: { run: '18', fhr: '7' },
    2: { run: '18', fhr: '8' },
    3: { run: '18', fhr: '9' },
    4: { run: '00', fhr: '4' },
    5: { run: '00', fhr: '5' },
    6: { run: '00', fhr: '6' },
    7: { run: '00', fhr: '7' },
    8: { run: '00', fhr: '8' },
    9: { run: '00', fhr: '9' },
    10: { run: '06', fhr: '4' },
    11: { run: '06', fhr: '5' },
    12: { run: '06', fhr: '6' },
    13: { run: '06', fhr: '7' },
    14: { run: '06', fhr: '8' },
    15: { run: '06', fhr: '9' },
    16: { run: '12', fhr: '4' },
    17: { run: '12', fhr: '5' },
    18: { run: '12', fhr: '6' },
    19: { run: '12', fhr: '7' },
    20: { run: '12', fhr: '8' },
    21: { run: '12', fhr: '9' },
    22: { run: '18', fhr: '4' },
    23: { run: '18', fhr: '5' }
};