import { fetchClusters, fetchLightnings } from "../API";

export const generateLightningSource = async (map, liveLightningGeojson) => {
    const lightnings = await fetchLightnings();
    const clusters = await fetchClusters();

    const myClusterPolygons = map.addSource('clusterPolygons', { 'type': 'geojson', 'data': clusters.stormPolygons});
    // const myClusterCenterPoints = map.addSource('cluster_centerpoints', { 'type': 'geojson', 'data': clusters.stormCenterPoints });
    // const myInnerPolygons = map.addSource('innerPolygons', { 'type': 'geojson', 'data': clusters.innerStormPolygons});
    // const myClusterExtrapolationPolygons = map.addSource('extrapolationPolygons', { 'type': 'geojson', 'data': clusters.extrapolationPolygons})
    const myPoints = map.addSource('points', { 'type': 'geojson', 'data': lightnings });
    const myLiveLightning = map.addSource('liveLightning', { 'type': 'geojson', 'data': liveLightningGeojson} );
}

