import { FetchRadarImages, fetchRadarSites } from "../API";

let defaultRadarInterval;
let radarFrameRequest;
let myTimeout;

let radarSources = [];
let radarLayers = [];

let isPlaying = true; // Track if the radar loop is playing
let indexCurrent = 0; // Track the current index globally

let radarImages = [];
const indexEnd = 24; // Total number of radar images
const loopSpeed = 350; // Speed at which the loop goes (in milliseconds)
let initialPlay = true; // Track if it's the initial play after showing the last image
let firstLoop = true;
let manual = false;

export const resetRadarIntervals = map => {
    if (radarFrameRequest) {
        cancelAnimationFrame(radarFrameRequest);
        radarFrameRequest = null;
    }

    if (defaultRadarInterval) {
        clearInterval(defaultRadarInterval);
        defaultRadarInterval = null;
    }

    if (myTimeout) {
        clearTimeout(myTimeout);
        myTimeout = null;
    }

    if (radarLayers.length > 0) {
        radarLayers.forEach(layer => {
            if (map.getLayer(layer)) {
                map.removeLayer(layer);
            }
        });

        radarLayers = [];
    }

    if (radarSources.length > 0) {
        radarSources.forEach(source => {
            if (map.getSource(source)) {
                map.removeSource(source);
            }
        });

        radarSources = [];
    }
}

export const initDefaultRadarSite = async (map, dayNight) => {
    await generateRadarImagesSource(map, 'knmi', 'herwijnen', 'bref', '[[54.71024508190882, 0.49553064792915347], [54.71024508190882, 9.7801419412471], [48.96191028269945, 9.7801419412471], [48.96191028269945, 0.49553064792915347]]');

    defaultRadarInterval = setInterval(async () => {
        await generateRadarImagesSource(map, 'knmi', 'herwijnen', 'bref', '[[54.71024508190882, 0.49553064792915347], [54.71024508190882, 9.7801419412471], [48.96191028269945, 9.7801419412471], [48.96191028269945, 0.49553064792915347]]');
    }, 60000 * 5);

    const colorScaleDivRef = document.getElementById('colorscale');
    const colorScaleImage = document.getElementById('radar-colorscale-image');
    const displayStyle = window.getComputedStyle(colorScaleDivRef).display;

    if (displayStyle === 'none') {
        colorScaleImage.src = dayNight === "day"
            ? 'https://weatherportal.eu/assets/images/reflectivity_colorscale_blacklabels.png'
            : 'https://weatherportal.eu/assets/images/reflectivity_colorscale_whitelabels.png';
        colorScaleDivRef.style.display = 'flex';
    } else {
        colorScaleImage.src = dayNight === "day"
            ? 'https://weatherportal.eu/assets/images/reflectivity_colorscale_blacklabels.png'
            : 'https://weatherportal.eu/assets/images/reflectivity_colorscale_whitelabels.png';
    }

    setupEventListeners(map);
}

export const generateRadarSource = async map => {
    const radarSites = await fetchRadarSites();
    map.addSource('radarSites', { 'type': "geojson", 'data': radarSites });
}

export const generateRadarImagesSource = async (map, dataCountry, dataId, dataType, bounds) => {

    firstLoop = true;

    // Clear existing animation frame and timeout
    if (radarFrameRequest) {
        cancelAnimationFrame(radarFrameRequest);
        radarFrameRequest = null;
    }

    if (myTimeout) {
        clearTimeout(myTimeout);
        myTimeout = null;
    }

    radarImages = await FetchRadarImages(dataCountry, dataId, dataType);

    if (radarLayers.length > 0) {
        radarLayers.forEach(layer => {
            if (map.getLayer(layer)) {
                map.removeLayer(layer);
            }
        });
        radarLayers = [];
    }

    if (radarSources.length > 0) {
        radarSources.forEach(source => {
            if (map.getSource(source)) {
                map.removeSource(source);
            }
        });
        radarSources = [];
    }

    indexCurrent = 0; // Reset the current index

    const boundsAsArray = JSON.parse(bounds);
    const boundsLonLat = boundsAsArray.map(subArray => [subArray[1], subArray[0]]);

    const generateFilePath = (index) => {
        const { fileName, fullTimeStamp } = radarImages.files[index];
        return `https://radar-tiles.supercell.weatherportal.eu/single/${dataCountry}/${dataId}/${dataType}/${fullTimeStamp}/${fileName}`;
    };

    // Add sources and layers for all images
    radarImages.files.forEach((_, index) => {
        const url = generateFilePath(index);
        const sourceId = `singleRadar-${index}`;
        const layerId = `radar-layer-${index}`;

        radarSources.push(sourceId);
        radarLayers.push(layerId);

        if (map.getLayer(layerId) !== undefined) {
            map.removeLayer(layerId);
        }

        if (map.getSource(sourceId) !== undefined) {
            map.removeSource(sourceId);
        }

        map.addSource(sourceId, {
            type: 'image',
            url: url,
            coordinates: boundsLonLat
        });

        map.addLayer({
            id: layerId,
            type: 'raster',
            source: sourceId,
            paint: {
                'raster-fade-duration': 0,
                'raster-resampling': 'nearest',
                'raster-opacity': window.localStorage.getItem('radarImageVisibility') === 'visible' ? 0.65 : 0
            },
            layout: {
                'visibility': index === 0 ? 'visible' : 'none'
            }
        }, 'clusterLayerLines');

        if (index === 0) {
            map.moveLayer(layerId, 'lightnings');
        }
    });

    if (isPlaying) {
        initialPlay = false; // Ensure initial play is false if the loop is already playing
        radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
    } else {
        showRadarImage(map, indexEnd - 1); // Show the last image if the loop is not playing
        const playPauseBtn = document.getElementById('playpause');
        playPauseBtn.classList.remove('fa-pause');
        playPauseBtn.classList.add('fa-play');
        initialPlay = true; // Set initial play flag
    }
};

const updateRadarImage = (map) => {
    const previousIndex = (indexCurrent - 1 + indexEnd) % indexEnd;
    const currentIndex = indexCurrent;

    const previousLayerId = `radar-layer-${previousIndex}`;
    const currentLayerId = `radar-layer-${currentIndex}`;

    if (map.getLayer(previousLayerId)) {
        map.setLayoutProperty(previousLayerId, 'visibility', 'none');
    }
    if (map.getLayer(currentLayerId)) {
        map.setLayoutProperty(currentLayerId, 'visibility', 'visible');
    }

    document.getElementById('time').innerHTML = radarImages.files[currentIndex].time;

    indexCurrent = (indexCurrent + 1) % indexEnd;

    if (currentIndex === 23) {
        if (isPlaying) {
            if(firstLoop) {
                myTimeout = setTimeout(() => {
                    radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
                }, loopSpeed - (50 * window.localStorage.getItem('radarSpeedLoop')));
            } else {

                if(manual) {
                    myTimeout = setTimeout(() => {
                        radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
                    }, loopSpeed - (50 * window.localStorage.getItem('radarSpeedLoop')));
                } else {
                    myTimeout = setTimeout(() => {
                        radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
                    }, window.localStorage.getItem('radarPause') * 1000);
                }

            }

        }
    } else {
        if (isPlaying) {
            myTimeout = setTimeout(() => {
                radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
            }, loopSpeed - (50 * window.localStorage.getItem('radarSpeedLoop')));
        }
    }

    firstLoop = false;
    manual = false;
};

// Function to enable controls
const enableControls = () => {
    const controls = document.querySelectorAll('#radarcontrol .control');

    controls.forEach(control => {
        control.classList.remove('inactive');
        control.disabled = false;
    });
};

// Function to disable controls
const disableControls = () => {
    const controls = document.querySelectorAll('#radarcontrol .control');

    controls.forEach(control => {
        if (!control.classList.contains('playpause')) {
            control.classList.add('inactive');
            control.disabled = true;
        }
    });
};

const showRadarImage = (map, index) => {
    radarLayers.forEach((layerId, layerIndex) => {
        if (map.getLayer(layerId)) {
            map.setLayoutProperty(layerId, 'visibility', layerIndex === index ? 'visible' : 'none');
        }
    });

    document.getElementById('time').innerHTML = radarImages.files[index].time;

    indexCurrent = index;
};

const togglePlayPause = (map) => {
    isPlaying = !isPlaying;
    const playPauseBtn = document.getElementById('playpause');
    playPauseBtn.classList.toggle('fa-pause', isPlaying);
    playPauseBtn.classList.toggle('fa-play', !isPlaying);

    if (isPlaying) {
        disableControls();
        if (indexCurrent === indexEnd - 1 && initialPlay) {
            initialPlay = false;
            radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
        } else {
            radarFrameRequest = requestAnimationFrame(() => updateRadarImage(map));
        }
    } else {
        enableControls();
        cancelAnimationFrame(radarFrameRequest);
        radarFrameRequest = null;
        clearTimeout(myTimeout);
    }
};

// Setup event listeners once
const setupEventListeners = (map) => {
    const playPauseBtn = document.getElementById('playpause');
    const previousBtn = document.getElementById('previous');
    const nextBtn = document.getElementById('next');
    const firstBtn = document.getElementById('first');
    const lastBtn = document.getElementById('last');

    playPauseBtn.addEventListener('click', () => togglePlayPause(map));

    previousBtn.addEventListener('click', () => {
        manual = true;
        if (!isPlaying) {
            const newIndex = (indexCurrent - 1 + indexEnd) % indexEnd;
            showRadarImage(map, newIndex);
        }
    });

    nextBtn.addEventListener('click', () => {
        manual = true;
        if (!isPlaying) {
            const newIndex = (indexCurrent + 1) % indexEnd;
            showRadarImage(map, newIndex);
        }
    });

    firstBtn.addEventListener('click', () => {
        manual = true;
        if (!isPlaying) {
            showRadarImage(map, 0); // Show the first image
        }
    });

    lastBtn.addEventListener('click', () => {
        manual = true;
        if (!isPlaying) {
            showRadarImage(map, indexEnd - 1); // Show the last image
        }
    });
};