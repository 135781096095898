export const initDefaultLocalStorage = () => {

    let mapLat, mapLon, mapZoom, mapStyle, dayNight, nwpVisibility, historyVisibility,
        polylineVisibility, polygonVisibility, locationVisibility, liveVisibility,
        sound, mslpVisibility, precipVisibility, satVisibility, wind500MbVisibility,
        radarImageVisibility, satWvVisibility, satVisVisibility, radarSpeedLoop, radarPause,
        pvVisibility, capeVisibility;

    if(window.localStorage.getItem('location')) {
        mapLat = JSON.parse(window.localStorage.getItem('location')).latitude;
        mapLon = JSON.parse(window.localStorage.getItem('location')).longitude;
    }
    else {
        mapLat = "51.2194475";
        mapLon = "4.4024643";
        window.localStorage.setItem('location', JSON.stringify(
            {
                latitude: mapLat,
                longitude: mapLon
            }
        ));
    }

    if(window.localStorage.getItem('radarSpeedLoop')) {
        radarSpeedLoop = window.localStorage.getItem('radarSpeedLoop');
    } else {
        radarSpeedLoop = '1';
        window.localStorage.setItem('radarSpeedLoop', radarSpeedLoop)
    }

    if(window.localStorage.getItem('radarPause')) {
        radarPause = window.localStorage.getItem('radarPause');
    } else {
        radarPause = '1';
        window.localStorage.setItem('radarPause', radarPause)
    }

    if(window.localStorage.getItem('zoom')) { mapZoom = window.localStorage.getItem('zoom'); }
    else { mapZoom = 5; window.localStorage.setItem('zoom', 5); }

    if(window.localStorage.getItem('mapStyle')) { mapStyle = window.localStorage.getItem('mapStyle'); }
    else {
        mapStyle = "https://api.maptiler.com/maps/8f466830-395b-4b87-91fc-cefc68e4e6bc/style.json?key=aGer1AJTBcteI29TXWzU";
        window.localStorage.setItem(
            'mapStyle',
            "https://api.maptiler.com/maps/8f466830-395b-4b87-91fc-cefc68e4e6bc/style.json?key=aGer1AJTBcteI29TXWzU"
        );
    }

    if(window.localStorage.getItem('dayNight')) { dayNight = window.localStorage.getItem('dayNight'); }
    else { dayNight = 'day'; window.localStorage.setItem('dayNight', 'day'); }

    if(window.localStorage.getItem('polylineVisibility')) { polylineVisibility = window.localStorage.getItem('polylineVisibility'); }
    else { polylineVisibility = 'visible'; window.localStorage.setItem('polylineVisibility', 'visible'); }

    if(window.localStorage.getItem('polygonVisibility')) { polygonVisibility = window.localStorage.getItem('polygonVisibility'); }
    else { polygonVisibility = 'visible'; window.localStorage.setItem('polygonVisibility', 'visible'); }

    if(window.localStorage.getItem('locationsVisibility')) { locationVisibility = window.localStorage.getItem('locationsVisibility'); }
    else { locationVisibility = 'visible'; window.localStorage.setItem('locationsVisibility', 'visible'); }

    if(window.localStorage.getItem('nwpVisibility')) { nwpVisibility = window.localStorage.getItem('nwpVisibility'); }
    else { nwpVisibility = 'visible'; window.localStorage.setItem('nwpVisibility', 'visible'); }

    if(window.localStorage.getItem('500mbWindVisibility')) { wind500MbVisibility = window.localStorage.getItem('500mbWindVisibility'); }
    else { wind500MbVisibility = 'visible'; window.localStorage.setItem('500mbWindVisibility', 'visible'); }

    if(window.localStorage.getItem('historyVisibility')) { historyVisibility = window.localStorage.getItem('historyVisibility'); }
    else { historyVisibility = 'visible'; window.localStorage.setItem('historyVisibility', 'visible'); }

    if(window.localStorage.getItem('liveVisibility')) { liveVisibility = window.localStorage.getItem('liveVisibility'); }
    else { liveVisibility = 'visible'; window.localStorage.setItem('liveVisibility', 'visible'); }

    if(window.localStorage.getItem('sound')) { sound = window.localStorage.getItem('sound'); }
    else { sound = 'audible'; window.localStorage.setItem('sound', 'audible'); }

    if(window.localStorage.getItem('mslpVisibility')) { mslpVisibility = window.localStorage.getItem('mslpVisibility'); }
    else { mslpVisibility = 'visible'; window.localStorage.setItem('mslpVisibility', 'visible'); }

    if(window.localStorage.getItem('precipVisibility')) { precipVisibility = window.localStorage.getItem('precipVisibility'); }
    else { precipVisibility = 'visible'; window.localStorage.setItem('precipVisibility', 'visible'); }

    if(window.localStorage.getItem('satVisibility')) { satVisibility = window.localStorage.getItem('satVisibility'); }
    else { satVisibility = 'visible'; window.localStorage.setItem('satVisibility', 'visible'); }

    if(window.localStorage.getItem('satVisVisibility')) { satVisVisibility = window.localStorage.getItem('satVisVisibility'); }
    else { satVisVisibility = 'none'; window.localStorage.setItem('satVisVisibility', 'none'); }

    if(window.localStorage.getItem('satWvVisibility')) { satWvVisibility = window.localStorage.getItem('satWvVisibility'); }
    else { satWvVisibility = 'none'; window.localStorage.setItem('satWvVisibility', 'none'); }

    if(window.localStorage.getItem('radarImageVisibility')) { radarImageVisibility = window.localStorage.getItem('radarImageVisibility'); }
    else { radarImageVisibility = 'visible'; window.localStorage.setItem('radarImageVisibility', 'visible'); }

    if(window.localStorage.getItem('capeVisibility')) { capeVisibility = window.localStorage.getItem('capeVisibility'); }
    else { capeVisibility = 'none'; window.localStorage.setItem('capeVisibility', 'none'); }

    if(window.localStorage.getItem('pvVisibility')) { pvVisibility = window.localStorage.getItem('pvVisibility'); }
    else { pvVisibility = 'none'; window.localStorage.setItem('pvVisibility', 'none'); }

    // console.log('visibility is', wind500MbVisibility);

    return {
        mapLat,
        mapLon,
        mapZoom,
        mapStyle,
        dayNight,
        nwpVisibility,
        liveVisibility,
        sound,
        polylineVisibility,
        polygonVisibility,
        locationVisibility,
        historyVisibility,
        mslpVisibility,
        precipVisibility,
        satVisibility,
        wind500MbVisibility,
        radarImageVisibility,
        satVisVisibility,
        satWvVisibility,
        radarSpeedLoop,
        radarPause,
        capeVisibility,
        pvVisibility
    };
};