import {
    createGPM500Layer,
    createGPMlabelLayer,
    createMSLPlabelLayer,
    createMSLPLayer, createWS500Layer,
    createWS500LayerContours,
    createPres2PVULayer, createPres2PVULayerContours
} from "../layerstyle/layerstyle";

export const addNWPLayers = (map, dayNight) => {
    map.addLayer(createMSLPLayer(dayNight));
    map.addLayer(createMSLPlabelLayer(dayNight, 'mslpLayer'));
    map.addLayer(createGPM500Layer(dayNight, 'mslpLabel'));
    map.addLayer(createGPMlabelLayer(dayNight), '500mbHeightLayer');
    map.addLayer(createWS500LayerContours(dayNight), 'mslpLabel');
    map.addLayer(createWS500Layer(dayNight), 'mslpLabel');
    map.addLayer(createPres2PVULayer(dayNight), 'mslpLabel')
    //map.addLayer(createPres2PVULayerContours(dayNight), 'mslpLabel')
}
