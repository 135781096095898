import { v4 as uuidv4 } from 'uuid';
let active = false;

export const setIntervalSatellite = map => {
    map.on('appInBackground', () => {
        // console.log('app is in background');
        active = false;
    })

    map.on('appInForeground', () => {
        // console.log('app is in foreground')
        active = true;
    })

    setInterval(() => {
        // console.log(map.getSource('ir108'));
        // console.log(map.getSource('rdt'));

        if(active) {
            map.getSource('ir108').setTiles([`https://view.eumetsat.int/geoserver/ows?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&version=1.3.0&layers=msg_fes:ir108&styles=&idofrequest=${uuidv4()}&transparent=true&format=image/png&crs=EPSG:3857&width=256&height=256`]);
            map.getSource('irWv').setTiles([`https://view.eumetsat.int/geoserver/ows?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&version=1.3.0&layers=msg_fes:wv062&styles=&idofrequest=${uuidv4()}&transparent=true&format=image/png&crs=EPSG:3857&width=256&height=256`]);
            map.getSource('satVis').setTiles([`https://view.eumetsat.int/geoserver/ows?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&version=1.3.0&layers=msg_fes:rgb_eview&styles=&idofrequest=${uuidv4()}&transparent=true&format=image/png&crs=EPSG:3857&width=256&height=256`]);

        }

    }, 5 * 60000);
};