export const restoreUI = (
    dayNight,
    polylineVisibility,
    polygonVisibility,
    sound,
    locationVisibility,
    nwpVisibility,
    liveVisibility,
    historyVisibility,
    mslpVisibility,
    precipVisibility,
    satVisibility,
    wind500mbVisibility,
    radarImageVisibility,
    satVisVisibility,
    satWvVisibility,
    radarSpeedLoop,
    radarPause,
    capeVisibility,
    pvVisibility
) => {

    const nightIcon = document.getElementById('map-styler');
    // const locationToggler = document.getElementById('location-toggler');
    const polygonToggler = document.getElementById('polygon-toggler');
    const polylineToggler = document.getElementById('polyline-toggler');
    const historyToggler = document.getElementById('history-toggler');
    const liveToggler = document.getElementById('live-toggler');
    const soundToggler = document.getElementById('sound-toggler');
    const nwpToggler = document.getElementById('nwp-toggler');
    const mslpToggler = document.getElementById('mslp-toggler');
    const precipToggler = document.getElementById('precip-toggler');
    const satToggler = document.getElementById('satellite-toggler');
    const wind500mbToggler = document.getElementById('500mbwinds-toggler');
    const timestamp = document.getElementById('time');
    const radarImageToggler = document.getElementById('radar-image-toggler');
    const satWvToggler = document.getElementById('satwv-toggler');
    const satVisToggler = document.getElementById('satvis-toggler');
    const radarSpeedSlider = document.getElementById('myRange');
    const radarPauseSlider = document.getElementById('myPauseRange');
    // const capeToggler = document.getElementById('cape-toggler');
    const pvToggler = document.getElementById('pv-toggler');

    if(dayNight === "day") {
        nightIcon.src = "https://weatherportal.eu/assets/images/iconMoon.png";
        timestamp.style.color = 'rgba(0, 0, 0, 0.8)'
    }
    else {
        console.log('Im in here');
        nightIcon.src = "https://weatherportal.eu/assets/images/iconSun.png";
        timestamp.style.color = 'rgba(255, 255, 255, 0.8)'
    }

    if(polylineVisibility === "visible") { polylineToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else {polylineToggler.src = "https://weatherportal.eu/assets/images/switch-off.png";}

    if(polygonVisibility === "visible") { polygonToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else {polygonToggler.src = "https://weatherportal.eu/assets/images/switch-off.png";}

    // if(locationVisibility === "visible") { locationToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    // else { locationToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(nwpVisibility === "visible") { nwpToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { nwpToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    // if(capeVisibility === "visible") { capeToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    // else { capeToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(mslpVisibility === "visible") { mslpToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { mslpToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(pvVisibility === "visible") { pvToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { pvToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(historyVisibility === "visible") { historyToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { historyToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(liveVisibility === "visible") { liveToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { liveToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(precipVisibility === "visible") { precipToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { precipToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(satVisibility === "visible") { satToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { satToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(satVisVisibility === "visible") { satVisToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { satVisToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(satWvVisibility === "visible") { satWvToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { satWvToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(sound === "audible") { soundToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { soundToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(wind500mbVisibility === "visible") { wind500mbToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { wind500mbToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    if(radarImageVisibility === "visible") { radarImageToggler.src = "https://weatherportal.eu/assets/images/switch-on.png"; }
    else { radarImageToggler.src = "https://weatherportal.eu/assets/images/switch-off.png"; }

    const maxSpeed = radarSpeedSlider.max;
    const valueRadarSpeed = parseInt(radarSpeedLoop);
    const percentageRadarSpeed = (valueRadarSpeed / maxSpeed) * 100;

    const colorSpeedSlider = `linear-gradient(90deg, rgb(117, 252, 117) ${percentageRadarSpeed}%, rgb(120, 120, 120) ${percentageRadarSpeed}%)`;

    radarSpeedSlider.value = radarSpeedLoop;
    radarSpeedSlider.style.background = colorSpeedSlider;


    const maxPause = radarPauseSlider.max;
    const valueRadarPause = parseInt(radarPause);
    const percentageRadarPause = (valueRadarPause / maxPause) * 100;

    const colorPauseSlider = `linear-gradient(90deg, rgb(117, 252, 117) ${percentageRadarPause}%, rgb(120, 120, 120) ${percentageRadarPause}%)`;

    radarPauseSlider.value = radarPause;
    radarPauseSlider.style.background = colorPauseSlider;




};