import maplibregl from 'maplibre-gl';
import { featureCollection } from "@turf/turf";
import { initiateLightningSocket } from "./map/layers/lightning/websocket";
import { initDefaultLocalStorage } from "./state/localstorage";
import { restoreUI } from "./UI/UI";
import { setupEventListeners } from "./listeners/eventListeners";
import { clickEvent, dragEvent, clickRadarSiteEvent } from "./map/events";
import '../styles/index.scss';
import { addMapLayers } from "./map/layers/layers";
import { generateMapSources } from "./map/layers/sources";
import { generateIntervals } from "./map/layers/intervals";
import { initDefaultRadarSite, resetRadarIntervals } from "./map/layers/radar/source/source";
import {generateWindy} from "../scripts/windy";

import 'maplibre-gl/dist/maplibre-gl.css';

const spinnerIcon = document.getElementById('spinner');

// Init datasets
const liveLightningGeojson = featureCollection([]);
const centerPointHistory = featureCollection([]);

// Mobile detection
const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

// Localstorage handling
const defaultLocalStorage = initDefaultLocalStorage();
const {
    mapLat,
    mapLon,
    mapZoom,
    mapStyle,
    dayNight,
    nwpVisibility,
    liveVisibility,
    sound,
    polylineVisibility,
    polygonVisibility,
    locationVisibility,
    historyVisibility,
    mslpVisibility,
    precipVisibility,
    satVisibility,
    wind500MbVisibility,
    radarImageVisibility,
    satWvVisibility,
    satVisVisibility,
    radarSpeedLoop,
    radarPause,
    capeVisibility,
    pvVisibility
} = defaultLocalStorage;

// Initiate map
let map;

document.addEventListener('deviceready', () => {
    console.log('Device is ready');
    // initializeMap();
});

const initializeMap = () => {
    console.log('Initializing map');
    map = new maplibregl.Map({
        container: 'map',
        style: mapStyle,
        center: [parseFloat(mapLon), parseFloat(mapLat)],
        zoom: parseInt(mapZoom),
        maxPitch: 85,
        attributionControl: false,
        maxBounds: [
            [-75, 27.5],   // SW
            [88, 71.25]    // NE
        ],
        customAttribution: "Some attrib"
    }).addControl(new maplibregl.NavigationControl());

    // Function to add geolocate control
    const addGeolocateControl = () => {
        console.log('Adding geolocate control');
        const geolocateControl = new maplibregl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        });

        // Check if the control is already added to the map
        if (!map.hasControl(geolocateControl)) {
            map.addControl(geolocateControl);
            console.log('Geolocate control added');
        } else {
            console.log('Geolocate control already exists');
        }

        localStorage.setItem('geolocationEnabled', 'true');
    };

    // Check if geolocation control should be added
    const checkGeolocationControl = () => {
        const geolocationEnabled = localStorage.getItem('geolocationEnabled');
        if (geolocationEnabled === 'true') {
            console.log('Geolocation was previously enabled');
            addGeolocateControl();
        } else {
            console.log('Requesting geolocation permission');
            requestGeolocation();
        }
    };

    // Request geolocation permissions and add control
    const requestGeolocation = () => {
        navigator.geolocation.getCurrentPosition(
            response => {
                console.log('Geolocation permissions granted');
                addGeolocateControl();
            }, error => {
                console.error('Geolocation error:', error);
                if (error.code === error.PERMISSION_DENIED) {
                    console.log('Permission denied. Please allow geolocation access in your browser settings.');
                } else if (error.code === error.POSITION_UNAVAILABLE) {
                    console.log('Position unavailable. Please check your device settings.');
                } else if (error.code === error.TIMEOUT) {
                    console.log('Request timed out. Please try again.');
                } else {
                    console.log('Geolocation error: ' + error.message);
                }
            }
        );
    };

    // Map event handlers
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            map.fire('appInBackground');
        } else if (document.visibilityState === 'visible') {
            map.fire('appInForeground');
        }
    });

    // On load async
    map.on('load', async () => {
        console.log('Map loaded');
        try {
            await generateMapSources(map, liveLightningGeojson);
            await addMapLayers(map, dayNight, precipVisibility, satVisibility, satWvVisibility, satVisVisibility, capeVisibility);
            await generateIntervals(map, liveLightningGeojson, centerPointHistory);
            initiateLightningSocket(map, liveLightningGeojson, sound);
            await initDefaultRadarSite(map, dayNight);
            checkGeolocationControl();
            // await generateWindy(map);
        } catch (error) {
            console.error('Error loading map:', error);
        }
    });

    // On load sync
    map.on('load', async () => {
        restoreUI(
            dayNight,
            polylineVisibility,
            polygonVisibility,
            sound,
            locationVisibility,
            nwpVisibility,
            liveVisibility,
            historyVisibility,
            mslpVisibility,
            precipVisibility,
            satVisibility,
            wind500MbVisibility,
            radarImageVisibility,
            satVisVisibility,
            satWvVisibility,
            radarSpeedLoop,
            radarPause,
            capeVisibility,
            pvVisibility
        );

        setupEventListeners(map, dayNight);

        const scale = 2;
        try {
            const response = await map.loadImage('https://weatherportal.eu/assets/images/radar_orange.png');
            map.addImage('radarSite', response.data);
        } catch (error) {
            console.error('Error loading image:', error);
        }
    });

    // On dragend
    map.on("dragend", () => {
        dragEvent(map);
        const currentZoom = map.getZoom();
        map.setZoom(currentZoom + 0.000001);
        map.setZoom(currentZoom);
    });

    const radarChoicePopup = new maplibregl.Popup({
        className: 'radar-tooltip',
        closeButton: false
    });

    // On click of a shoot location
    map.on('click', 'locations', async (e) => { await clickEvent(e, map); });
    map.on('click', 'radarSites', async (e) => { await clickRadarSiteEvent(e, map, radarChoicePopup, dayNight, radarImageVisibility); });

    // Change pointer when user hovers over the shoot locations & out again.
    map.on('mouseenter', 'locations', () => { map.getCanvas().style.cursor = 'pointer'; });
    map.on('mouseleave', 'locations', () => { map.getCanvas().style.cursor = ''; });

    map.on('mouseenter', 'radarSites', () => { map.getCanvas().style.cursor = 'pointer'; });
    map.on('mouseleave', 'radarSites', () => { map.getCanvas().style.cursor = ''; });

    // Close popup when user has chosen a radarType
    map.on('closeAllPopups', () => {
        radarChoicePopup.remove();
    });

    // Reset intervals when user has requested new radar data
    map.on('newRadarDataRequested', () => {
        resetRadarIntervals(map);
    });

    map.on('styledata', () => {
        if (map.getLayer('radarSites')) {
            map.moveLayer('radarSites');
        }
    });

    map.on('load', () => {
        spinnerIcon.style.display = 'none';
    });

    map.on('sourcedataloading', (e) => {
        if (!e.sourceId.includes('strike')) {
            spinnerIcon.style.display = 'block';
        }
    });

    map.on('sourcedata', (e) => {
        spinnerIcon.style.display = 'none';
    });

    map.on('layerdataloading', (e) => {
        if (!e.layerId.includes('live')) {
            spinnerIcon.style.display = 'block';
        }
    });

    map.on('layerdata', () => {
        spinnerIcon.style.display = 'none';
    });
};

initializeMap();


