export const setupEventListeners = (map, dayNight) => {

    const dayNightToggler = document.getElementById('map-styler');
    const locationToggler = document.getElementById('location-toggler');
    const polygonToggler = document.getElementById('polygon-toggler');
    const polylineToggler = document.getElementById('polyline-toggler');
    const liveToggler = document.getElementById('live-toggler');
    const historyToggler = document.getElementById('history-toggler');
    const soundToggler = document.getElementById('sound-toggler');
    const nwpToggler = document.getElementById('nwp-toggler');
    const mslpToggler = document.getElementById('mslp-toggler');
    const radarToggler = document.getElementById('precip-toggler');
    const satToggler = document.getElementById('satellite-toggler');
    const hamburgermenu = document.getElementById('hamburger-menu');
    const menu = document.querySelector('.menu');
    const settingsControl = document.getElementById('settingscontrol');
    const settings = document.querySelector('.settings');
    const closeMenu = document.getElementById('close-menu');
    const wind500MBToggler = document.getElementById('500mbwinds-toggler');
    const radarImageToggler = document.getElementById('radar-image-toggler');
    const satVisToggler = document.getElementById('satvis-toggler');
    const satWvToggler = document.getElementById('satwv-toggler');
    // const capeToggler = document.getElementById('cape-toggler');
    const pvToggler = document.getElementById('pv-toggler');

    dayNightToggler.addEventListener("click", () => {
        if(dayNight === "day") {
            window.localStorage.setItem('mapStyle', "https://api.maptiler.com/maps/1fa089f8-bbc0-4ac3-915c-9a2bd6d355a9/style.json?key=aGer1AJTBcteI29TXWzU");
            window.localStorage.setItem('dayNight', 'night');
            document.location.reload(true);
        } else {
            window.localStorage.setItem('mapStyle', "https://api.maptiler.com/maps/8f466830-395b-4b87-91fc-cefc68e4e6bc/style.json?key=aGer1AJTBcteI29TXWzU");
            window.localStorage.setItem('dayNight', "day");
            document.location.reload(true);
        }
    });

    // locationToggler.addEventListener('click', () => {
    //     const locationsVisibility = window.localStorage.getItem('locationsVisibility');
    //
    //     if (locationsVisibility === 'visible') {
    //         map.setLayoutProperty('locations', 'visibility', 'none');
    //         window.localStorage.setItem('locationsVisibility', 'none');
    //         locationToggler.setAttribute(
    //             'src', 'https://weatherportal.eu/assets/images/switch-off.png'
    //         );
    //     } else {
    //         map.setLayoutProperty('locations', 'visibility', 'visible');
    //         window.localStorage.setItem('locationsVisibility', 'visible');
    //         locationToggler.setAttribute(
    //             'src', 'https://weatherportal.eu/assets/images/switch-on.png'
    //         );
    //     }
    // });

    polygonToggler.addEventListener('click', () => {
        const polygonsVisibility = window.localStorage.getItem('polygonVisibility')
            ? window.localStorage.getItem('polygonVisibility')
            : 'visible';

        if (polygonsVisibility === 'visible') {
            map.setLayoutProperty('clusterLayer', 'visibility', 'none');
            window.localStorage.setItem('polygonVisibility', 'none');
            polygonToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('clusterLayer', 'visibility', 'visible');
            window.localStorage.setItem('polygonVisibility', 'visible');
            polygonToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    polylineToggler.addEventListener('click', () => {
        const polylinesVisibility = window.localStorage.getItem('polylineVisibility')
            ? window.localStorage.getItem('polylineVisibility')
            : 'visible';

        if (polylinesVisibility === 'visible') {
            map.setLayoutProperty('clusterLayerLines', 'visibility', 'none');
            window.localStorage.setItem('polylineVisibility', 'none');
            polylineToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('clusterLayerLines', 'visibility', 'visible');
            window.localStorage.setItem('polylineVisibility', 'visible');
            polylineToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });




    // capeToggler.addEventListener('click', () => {
    //     const capeVisibility = window.localStorage.getItem('capeVisibility')
    //         ? window.localStorage.getItem('capeVisibility')
    //         : 'none';
    //
    //     if (capeVisibility === 'visible') {
    //         map.setLayoutProperty('sbcape', 'visibility', 'none');
    //         window.localStorage.setItem('capeVisibility', 'none');
    //         capeToggler.setAttribute(
    //             'src', 'https://weatherportal.eu/assets/images/switch-off.png'
    //         );
    //     } else {
    //         map.setLayoutProperty('sbcape', 'visibility', 'visible');
    //         window.localStorage.setItem('capeVisibility', 'visible');
    //         capeToggler.setAttribute(
    //             'src', 'https://weatherportal.eu/assets/images/switch-on.png'
    //         );
    //     }
    // });


    pvToggler.addEventListener('click', () => {
        const pvVisibility = window.localStorage.getItem('pvVisibility')
            ? window.localStorage.getItem('pvVisibility')
            : 'visible';

        if (pvVisibility === 'visible') {
            map.setLayoutProperty('pres2PVULayer', 'visibility', 'none');
            window.localStorage.setItem('pvVisibility', 'none');
            pvToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('pres2PVULayer', 'visibility', 'visible');
            window.localStorage.setItem('pvVisibility', 'visible');
            pvToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    nwpToggler.addEventListener('click', () => {
        const nwpVisibility = window.localStorage.getItem('nwpVisibility')
            ? window.localStorage.getItem('nwpVisibility')
            : 'visible';

        if (nwpVisibility === 'visible') {
            map.setLayoutProperty('500mbHeightLayer', 'visibility', 'none');
            map.setLayoutProperty('gpmlabel', 'visibility', 'none');
            window.localStorage.setItem('nwpVisibility', 'none');
            nwpToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('500mbHeightLayer', 'visibility', 'visible');
            map.setLayoutProperty('gpmlabel', 'visibility', 'visible');
            window.localStorage.setItem('nwpVisibility', 'visible');
            nwpToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    wind500MBToggler.addEventListener('click', () => {
        const wind500MbVisibility = window.localStorage.getItem('500mbWindVisibility')
            ? window.localStorage.getItem('500mbWindVisibility')
            : 'visible';

        if (wind500MbVisibility === 'visible') {
            map.setLayoutProperty('500mbWindspeedLayer', 'visibility', 'none');
            map.setLayoutProperty('500mbWindspeedFillLayer', 'visibility', 'none');
            window.localStorage.setItem('500mbWindVisibility', 'none');
            wind500MBToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('500mbWindspeedLayer', 'visibility', 'visible');
            map.setLayoutProperty('500mbWindspeedFillLayer', 'visibility', 'visible');
            window.localStorage.setItem('500mbWindVisibility', 'visible');
            wind500MBToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    mslpToggler.addEventListener('click', () => {
        const mslpVisibility = window.localStorage.getItem('mslpVisibility')
            ? window.localStorage.getItem('mslpVisibility')
            : 'visible';

        if (mslpVisibility === 'visible') {
            map.setLayoutProperty('mslpLayer', 'visibility', 'none');
            map.setLayoutProperty('mslpLabel', 'visibility', 'none');
            window.localStorage.setItem('mslpVisibility', 'none');
            mslpToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('mslpLayer', 'visibility', 'visible');
            map.setLayoutProperty('mslpLabel', 'visibility', 'visible');
            window.localStorage.setItem('mslpVisibility', 'visible');
            mslpToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    historyToggler.addEventListener('click', () => {
        const historyVisibility = window.localStorage.getItem('historyVisibility')
            ? window.localStorage.getItem('historyVisibility')
            : 'visible';

        if (historyVisibility === 'visible') {
            map.setLayoutProperty('lightnings', 'visibility', 'none');
            map.setLayoutProperty('liveLightnings', 'visibility', 'none');
            window.localStorage.setItem('historyVisibility', 'none');
            historyToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            map.setLayoutProperty('lightnings', 'visibility', 'visible');
            map.setLayoutProperty('liveLightnings', 'visibility', 'visible');

            window.localStorage.setItem('historyVisibility', 'visible');
            historyToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    liveToggler.addEventListener('click', () => {
        const liveVisibility = window.localStorage.getItem('liveVisibility')
            ? window.localStorage.getItem('liveVisibility')
            : 'visible';

        if (liveVisibility === 'visible') {
            window.localStorage.setItem('liveVisibility', 'none');
            liveToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            window.localStorage.setItem('liveVisibility', 'visible');
            liveToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    soundToggler.addEventListener('click', () => {
        const sound = window.localStorage.getItem('sound')
            ? window.localStorage.getItem('sound')
            : 'audible';

        if (sound === 'audible') {
            window.localStorage.setItem('sound', 'none');
            soundToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            window.localStorage.setItem('sound', 'audible');
            soundToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    radarToggler.addEventListener('click', () => {
        const precipVisibility = window.localStorage.getItem('precipVisibility')
            ? window.localStorage.getItem('precipVisibility')
            : 'visible';

        if (precipVisibility === 'visible') {
            map.getLayer('radarSites') && map.setLayoutProperty('radarSites', 'visibility', 'none');
            window.localStorage.setItem('precipVisibility', 'none');
            radarToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            window.localStorage.setItem('precipVisibility', 'visible');
            map.getLayer('radarSites') && map.setLayoutProperty('radarSites', 'visibility', 'visible');

            radarToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    radarImageToggler.addEventListener('click', () => {
        const radarImageVisibility = window.localStorage.getItem('radarImageVisibility')
            ? window.localStorage.getItem('radarImageVisibility')
            : 'visible';

        if (radarImageVisibility === 'visible') {

            for(let i = 0; i < 24; i++) {
                map.getLayer(`radar-layer-${i}`) && map.setPaintProperty(`radar-layer-${i}`, 'raster-opacity', 0);

            }
            window.localStorage.setItem('radarImageVisibility', 'none');
            radarImageToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            for(let i = 0; i < 24; i++) {
                map.getLayer(`radar-layer-${i}`) && map.setPaintProperty(`radar-layer-${i}`, 'raster-opacity', 0.65);

            }
            window.localStorage.setItem('radarImageVisibility', 'visible');
            radarImageToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    satToggler.addEventListener('click', () => {
        const satVisibility = window.localStorage.getItem('satVisibility')
            ? window.localStorage.getItem('satVisibility')
            : 'visible';

        if (satVisibility === 'visible') {
            //map.getLayer('satRdt') && map.setLayoutProperty('satRdt', 'visibility', 'none');
            map.getLayer('satIr108') && map.setLayoutProperty('satIr108', 'visibility', 'none');
            window.localStorage.setItem('satVisibility', 'none');
            satToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            // map.getLayer('satRdt') && map.setLayoutProperty('satRdt', 'visibility', 'visible');
            map.getLayer('satIr108') && map.setLayoutProperty('satIr108', 'visibility', 'visible');
            window.localStorage.setItem('satVisibility', 'visible');
            satToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    });

    satWvToggler.addEventListener('click', () => {
        const satWvVisibility = window.localStorage.getItem('satWvVisibility')
            ? window.localStorage.getItem('satWvVisibility')
            : 'visible';

        if (satWvVisibility === 'visible') {
            //map.getLayer('satRdt') && map.setLayoutProperty('satRdt', 'visibility', 'none');
            map.getLayer('satWv') && map.setLayoutProperty('satWv', 'visibility', 'none');
            window.localStorage.setItem('satWvVisibility', 'none');
            satWvToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            // map.getLayer('satRdt') && map.setLayoutProperty('satRdt', 'visibility', 'visible');
            map.getLayer('satWv') && map.setLayoutProperty('satWv', 'visibility', 'visible');
            window.localStorage.setItem('satWvVisibility', 'visible');
            satWvToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    })

    satVisToggler.addEventListener('click', () => {
        const satVisVisibility = window.localStorage.getItem('satVisVisibility')
            ? window.localStorage.getItem('satVisVisibility')
            : 'visible';

        if (satVisVisibility === 'visible') {
            //map.getLayer('satRdt') && map.setLayoutProperty('satRdt', 'visibility', 'none');
            map.getLayer('satVis') && map.setLayoutProperty('satVis', 'visibility', 'none');
            window.localStorage.setItem('satVisVisibility', 'none');
            satVisToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-off.png'
            );
        } else {
            // map.getLayer('satRdt') && map.setLayoutProperty('satRdt', 'visibility', 'visible');
            map.getLayer('satVis') && map.setLayoutProperty('satVis', 'visibility', 'visible');
            window.localStorage.setItem('satVisVisibility', 'visible');
            satVisToggler.setAttribute(
                'src', 'https://weatherportal.eu/assets/images/switch-on.png'
            );
        }
    })

    hamburgermenu.addEventListener('click', e => {
        e.stopPropagation();

        menu.classList.remove('menu-hidden');
        menu.classList.add('menu-on');
    });

    closeMenu.addEventListener('click', () => {
        menu.classList.remove('menu-on');
        menu.classList.add('menu-hidden');
    });

    settingsControl.addEventListener('click', e => {

        console.log('in settings control clicked')

        e.stopPropagation();

        settings.classList.remove('settings-hidden');
        settings.classList.add('settings-on');
    });

    document.body.addEventListener('click', e => {
        if(
            !menu.contains(e.target) &&
            e.target !== hamburgermenu &&
            !settings.contains(e.target)
        ) {
            menu.classList.remove('menu-on');
            menu.classList.add('menu-hidden');
            settings.classList.remove('settings-on');
            settings.classList.add('settings-hidden');
        }
    })

    // Function to add click effect
    const addClickEffect = (element) => {
        element.style.color = 'rgb(0,139,220)'; // Change to desired text color
    };

    // Function to remove click effect
    const removeClickEffect = (element) => {
        element.style.color = ''; // Revert to original text color
    };

    // Function to setup click effect event listeners
    const setupClickEffectListeners = () => {
        const controls = document.querySelectorAll('#radarcontrol .control');

        controls.forEach(control => {
            control.addEventListener('mousedown', () => addClickEffect(control));
            control.addEventListener('mouseup', () => removeClickEffect(control));
            control.addEventListener('mouseleave', () => removeClickEffect(control)); // Ensure text color resets if mouse leaves the element while pressed
        });
    };

    // Call this function in your setupEventListeners function or wherever appropriate
    setupClickEffectListeners();

    const slider = document.getElementById('myRange');

    slider.addEventListener('input', () => {
        const max = slider.max;
        const value = slider.value;
        const percentage = (value / max) * 100;
        const color = `linear-gradient(90deg, rgb(117, 252, 117) ${percentage}%, rgb(214, 214, 214) ${percentage}%)`;
        slider.style.background = color;
        window.localStorage.setItem('radarSpeedLoop', value.toString());

    });

    const radarPauseSlider = document.getElementById('myPauseRange');

    radarPauseSlider.addEventListener('input', () => {
        const max = radarPauseSlider.max;
        const value = radarPauseSlider.value;
        const percentage = (value / max) * 100;
        const color = `linear-gradient(90deg, rgb(117, 252, 117) ${percentage}%, rgb(214, 214, 214) ${percentage}%)`;
        radarPauseSlider.style.background = color;
        window.localStorage.setItem('radarPause', value.toString());

    });

    const headers = document.querySelectorAll('.submenu-header');

    headers.forEach(header => {
        header.addEventListener('click', () => {
            const content = header.nextElementSibling;
            const icon = header.querySelector('.toggle-icon');

            if (content.style.display === 'block') {
                content.style.display = 'none';
                icon.classList.remove('active');
            } else {
                content.style.display = 'block';
                icon.classList.add('active');
            }
        });
    });
};