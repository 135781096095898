import { cleanLiveLightning, fetchClusters, fetchLightnings } from "./API";
import {featureCollection} from "@turf/turf";

export const setLightningIntervals = (map, liveLightningGeoJson, centerPointHistory) => {

    // let stormPolygons = featureCollection([]);

    setInterval(async () => {
        const lightnings = await fetchLightnings();
        const clusters = await fetchClusters();

        // console.log('clusters');
        // console.log('********');
        // console.log(clusters);

        // centerPointHistory.features = [
        //     ...clusters.stormCenterPoints.features,
        //     ...centerPointHistory.features
        // ];

        // stormPolygons.features = [...stormPolygons.features, ...clusters.stormPolygons.features]

        await map.getSource('points').setData(lightnings);
        await map.getSource('clusterPolygons').setData(clusters.stormPolygons);
        //await map.getSource('cluster_centerpoints').setData(centerPointHistory);
        // await map.getSource('extrapolationPolygons').setData(clusters.extrapolationPolygons);
    }, 5 * 60000);

    setInterval(async () => {
        await cleanLiveLightning(liveLightningGeoJson);
        await map.getSource('liveLightning').setData(liveLightningGeoJson);
    }, 5 * 60000);
};