export const generateRadarSiteTooltip = info => {
    return (
        `
            <div id="radar-choice" class="radar-site-tooltip">
                <div class="radar-choice">
                     <div class="reflectivity bref choice-circle" data-type="bref" data-id="${info.site}" data-country="${info.country}">
                        <img src="https://weatherportal.eu/assets/images/reflectivity.png" alt="reflectivity" height="40px" width="40px" />
                    </div>
                    <div class="velocity bvel choice-circle" data-type="bvel" data-id="${info.site}" data-country="${info.country}">
                        <img src="https://weatherportal.eu/assets/images/velocity.png" alt="velocity" height="40px" width="40px" />
                    </div>
                </div>
            </div>
        `
    )
}