// Hourly fetching 500mb height contours from backend
import { fetch500MbHeightContours, fetch500mbWindspeedContours, fetchMSLPContours, fetchPres2PVUContours } from "./API";

export const setIntervalsGPMFetcher = (map) => {
    const now = new Date();
    const nextHour = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), 30, 0, 0);
    const difference = nextHour - now;

    window.setTimeout(async () => {
        const gpmHeight = await fetch500MbHeightContours();
        await map.getSource('gpm').setData(gpmHeight);

        setInterval(async () => {
            const gpmHeight = await fetch500MbHeightContours();
            await map.getSource('gpm').setData(gpmHeight);
        }, 3600000);

    }, difference);
};

export const setIntervalsMSLPFetcher = (map) => {
    const now = new Date();
    const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 30, 0, 0);
    const difference = nextHour - now;

    window.setTimeout(async () => {
        const mslp = await fetchMSLPContours();
        await map.getSource('mslp').setData(mslp);

        setInterval(async () => {
            const mslpres = await fetchMSLPContours();
            await map.getSource('mslp').setData(mslpres);
        }, 3600000);

    }, difference);
};

export const setIntervals500mbWindFetcher = (map) => {
    const now = new Date();
    const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 30, 0, 0);
    const difference = nextHour - now;

    window.setTimeout(async () => {
        const ws500mb = await fetch500mbWindspeedContours();
        await map.getSource('500mbWS').setData(ws500mb);

        setInterval(async () => {
            const ws500mb = await fetch500mbWindspeedContours();
            await map.getSource('500mbWS').setData(ws500mb);
        }, 3600000);

    }, difference);
};

export const setIntervalsPVFetcher = (map) => {
    const now = new Date();
    const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 30, 0, 0);
    const difference = nextHour - now;

    window.setTimeout(async () => {
        const pv = await fetchPres2PVUContours();
        await map.getSource('pres2PVU').setData(pv);

        setInterval(async () => {
            const pv = await fetchPres2PVUContours();
            await map.getSource('pres2PVU').setData(pv);
        }, 3600000);

    }, difference);
};