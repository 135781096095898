import maplibregl from "maplibre-gl";
import { generateTooltip } from "./layers/markers/tooltip";
import { generateRadarSiteTooltip } from './layers/radar/tooltip';
import { generateRadarImagesSource } from "./layers/radar/source/source";

let radarInterval;

export const dragEvent = map => {
    window.localStorage.setItem('location', JSON.stringify({
        latitude: map.getCenter().lat,
        longitude: map.getCenter().lng
    }));

    window.localStorage.setItem('zoom', map.getZoom());
};

export const clickEvent = async (e, map) => {
    const coordinates = e.features[0].geometry.coordinates.slice(0, 2);
    const tooltip = generateTooltip(e.features[0].properties, coordinates);

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    new maplibregl.Popup()
        .setLngLat(coordinates.slice(0, 2))
        .setHTML(await tooltip)
        .addTo(map);
};

export const clickRadarSiteEvent = async(e, map, radarChoicePopup, dayNight) => {
    // console.log('clickRadarSiteEvent');

    const coordinates = e.features[0].geometry.coordinates.slice(0, 2);
    const tooltip = generateRadarSiteTooltip(e.features[0].properties);

    const { site, country, boundsRef, boundsVel } = e.features[0].properties

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popupContent = document.createElement('div');
    popupContent.innerHTML = tooltip;

    radarChoicePopup
        .setLngLat(coordinates.slice(0, 2))
        .setDOMContent(popupContent)
        .addTo(map);

    popupContent.querySelector('.bref').addEventListener('click', event => {

        map.fire('newRadarDataRequested');


        if(radarInterval) {
            clearInterval(radarInterval);
        }

        const targetElement = event.target.closest('.bref');
        const dataId = targetElement.getAttribute('data-id').toLowerCase().replace(/ /g, '');
        const dataType = targetElement.getAttribute('data-type').toLowerCase();
        const dataCountry = targetElement.getAttribute('data-country').toLowerCase();

        // console.log(dataId, dataType, dataCountry);

        generateRadarImagesSource(map, dataCountry, dataId, dataType, boundsRef);

        map.fire('closeAllPopups');

        radarInterval = setInterval(
            () => generateRadarImagesSource(map, dataCountry, dataId, dataType, boundsRef),
            60000 * 5
        )

        const colorScaleDivRef = document.getElementById('colorscale');
        const colorScaleImage = document.getElementById('radar-colorscale-image');
        const displayStyle = window.getComputedStyle(colorScaleDivRef).display;


        // console.log(colorScaleDivRef.style.display);

        if (displayStyle === 'none') {
            colorScaleImage.src = dayNight === "day"
                ? 'https://weatherportal.eu/assets/images/reflectivity_colorscale_blacklabels.png'
                : 'https://weatherportal.eu/assets/images/reflectivity_colorscale_whitelabels.png'
            ;
            colorScaleDivRef.style.display = 'flex';
        } else {
            colorScaleImage.src = dayNight === "day"
                ? 'https://weatherportal.eu/assets/images/reflectivity_colorscale_blacklabels.png'
                : 'https://weatherportal.eu/assets/images/reflectivity_colorscale_whitelabels.png'
            ;
        }
    });

    popupContent.querySelector('.bvel').addEventListener('click', event => {

        map.fire('newRadarDataRequested');

        if(radarInterval) {
            clearInterval(radarInterval);
        }

        const targetElement = event.target.closest('.bvel');
        const dataId = targetElement.getAttribute('data-id').toLowerCase().replace(/ /g, '');
        const dataType = targetElement.getAttribute('data-type').toLowerCase();
        const dataCountry = targetElement.getAttribute('data-country').toLowerCase();

        // console.log(dataId, dataType, dataCountry);

        generateRadarImagesSource(map, dataCountry, dataId, dataType, boundsVel);

        map.fire('closeAllPopups');

        radarInterval = setInterval(
            () => generateRadarImagesSource(map, dataCountry, dataId, dataType, boundsVel),
            60000 * 5
        )

        const colorScaleDivRef = document.getElementById('colorscale');
        const colorScaleImage = document.getElementById('radar-colorscale-image');
        const displayStyle = window.getComputedStyle(colorScaleDivRef).display;


        // console.log(colorScaleDivRef.style.display);

        if (displayStyle === 'none') {
            colorScaleImage.src = dayNight === "day"
                ? 'https://weatherportal.eu/assets/images/velocity_colorscale_blacklabels.png'
                : 'https://weatherportal.eu/assets/images/velocity_colorscale_whitelabels.png'
            ;
            colorScaleDivRef.style.display = 'flex';
        } else {
            colorScaleImage.src = dayNight === "day"
                ? 'https://weatherportal.eu/assets/images/velocity_colorscale_blacklabels.png'
                : 'https://weatherportal.eu/assets/images/velocity_colorscale_whitelabels.png'
            ;
        }
    });
}