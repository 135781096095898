const spinnerIcon = document.getElementById('spinner');

export const fetchRadarSites = async () => {
    spinnerIcon.style.display = 'block'
    return fetch('https://radar-tiles.supercell.weatherportal.eu/sitelist')
        .then(res => res.json())
        .then(apiData => {
            spinnerIcon.style.display = 'none';
            // console.log(apiData);
            return apiData
        })
        .catch(e => {
            spinnerIcon.style.display = 'none';
        })
}

export const FetchRadarImages = async (dataCountry, dataId, dataType) => {
    spinnerIcon.style.display = 'block';

    return await fetch(
        `https://radar-tiles.supercell.weatherportal.eu/imagelist/${dataCountry}/${dataId}/${dataType}`
    )
        .then(res => res.json())
        .then(apiData => {
            spinnerIcon.style.display = 'none';
            return apiData
        })
        .catch(e => {
            spinnerIcon.style.display = 'none';
        });
}