import { v4 as uuidv4 } from "uuid";
import { addProtocol } from "maplibre-gl";

// Function to interpolate between two colors
const interpolateColor = (color1, color2, factor) => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
};

// Configuration for color steps and their ranges
const colorConfigWV = [
    { start: 0, end: 30, from: [0, 0, 0], to: [255, 215, 0] },       // Black to Gold
    { start: 31, end: 60, from: [255, 215, 0], to: [25, 25, 25] }, // Gold to Dark Gray
    { start: 61, end: 135, from: [25, 25, 25], to: [255, 255, 255] }, // Dark Gray to White
    { start: 136, end: 180, from: [255, 255, 255], to: [0, 0, 255] },  // White to Blue
    { start: 181, end: 256, from: [0, 0, 255], to: [0, 0, 0] }  // Blue to Black
];

const colorConfigWV2 = [
    { start: 0, end: 50, from: [0, 0, 0], to: [185, 185, 185] },       // Black to Gold
    // { start: 51, end: 11, from: [255, 215, 0], to: [25, 25, 25] }, // Gold to Dark Gray
    { start: 51, end: 155, from: [185, 185, 185], to: [255, 255, 255] }, // Dark Gray to White
    { start: 106, end: 140, from: [255, 255, 255], to: [0, 0, 255] },  // White to Blue
    { start: 141, end: 200, from: [0, 0, 255], to: [255, 0, 255] },  // Blue to Black
    { start: 201, end: 256, from: [0, 0, 255], to: [255, 0, 255] }  // Blue to Black
];

// Backup

// const colorConfigIR = [
//     { start: 0, end: 30, from: [0, 0, 0], to: [60, 60, 60] },       // Black to Dark Gray
//     { start: 31, end: 120, from: [60, 60, 60], to: [225, 225, 225] }, // Dark Gray to Light Gray
//     { start: 121, end: 160, from: [225, 225, 225], to: [74, 115, 243] }, // Light Gray to Light Blue
//     { start: 161, end: 170, from: [74, 115, 243], to: [34, 11, 253] },  // Light Blue to Blue
//     { start: 171, end: 185, from: [34, 11, 253], to: [0, 255, 0] },  // Blue to Green
//     { start: 186, end: 195, from: [0, 255, 0], to: [0, 125, 0] },  // Green to Dark Green
//     { start: 196, end: 200, from: [0, 125, 0], to: [240, 240, 0] },  // Dark Green to Yellow
//
//     { start: 201, end: 250, from: [240, 240, 0], to: [230, 0, 0] },  // Yellow to Red
//     // { start: 231, end: 245, from: [230, 0, 0], to: [180, 0, 255] },  // Red to Purple
//     // { start: 226, end: 235, from: [180, 0, 255], to: [255, 0, 255] },  // Purple to Magenta
//
//     { start: 251, end: 256, from: [230, 0, 0], to: [0, 0, 0] }  // Red to Black
// ];

const colorConfigIR = [
    { start: 0, end: 30, from: [0, 0, 0], to: [60, 60, 60] },       // Black to Dark Gray
    { start: 31, end: 120, from: [60, 60, 60], to: [225, 225, 225] }, // Dark Gray to Light Gray
    { start: 121, end: 135, from: [225, 225, 225], to: [255, 255, 255] }, // Light Gray to White
    { start: 136, end: 215, from: [255, 255, 255], to: [134, 185, 243] }, // Light Gray to Light Blue
    // { start: 161, end: 170, from: [74, 115, 243], to: [34, 11, 253] },  // Light Blue to Blue
    // { start: 171, end: 185, from: [34, 11, 253], to: [0, 255, 0] },  // Blue to Green
    // { start: 186, end: 195, from: [0, 255, 0], to: [0, 125, 0] },  // Green to Dark Green
    // { start: 196, end: 200, from: [0, 125, 0], to: [240, 240, 0] },  // Dark Green to Yellow

    { start: 216, end: 250, from: [134, 185, 243], to: [34, 11, 253] },  // Yellow to Red
    // { start: 231, end: 245, from: [230, 0, 0], to: [180, 0, 255] },  // Red to Purple
    // { start: 226, end: 235, from: [180, 0, 255], to: [255, 0, 255] },  // Purple to Magenta

    { start: 251, end: 256, from: [34, 11, 253], to: [0, 0, 0] }  // Red to Black
];

// Generate a color scale of 256 colors based on the configuration
const generateColorScale = (config) => {
    const scale = [];

    config.forEach(({ start, end, from, to }) => {
        for (let i = start; i <= end; i++) {
            const factor = (i - start) / (end - start);
            scale[i] = interpolateColor(from, to, factor);
        }
    });

    return scale;
};

const colorScaleWV = generateColorScale(colorConfigWV);
const colorScaleWV2 = generateColorScale(colorConfigWV2);
const colorScaleIR = generateColorScale(colorConfigIR);

// Create a single canvas for all tiles
const canvas = document.createElement('canvas');
canvas.width = 256; // Size of a single tile
canvas.height = 256;
const ctx = canvas.getContext('2d', { willReadFrequently: true });

addProtocol('enhancedWV', (params) => {
    return fetch(params.url.replace('enhancedWV://', ''))
        .then(t => {
            if (t.status === 200) {
                return t.blob()
                    .then(blob => {
                        const img = new Image();
                        img.src = URL.createObjectURL(blob);

                        return new Promise((resolve) => {
                            img.onload = function () {
                                // Clear the canvas and make it fully transparent
                                ctx.clearRect(0, 0, canvas.width, canvas.height);
                                ctx.fillStyle = "rgba(0, 0, 0, 0)";
                                ctx.fillRect(0, 0, canvas.width, canvas.height);

                                ctx.drawImage(img, 0, 0, 256, 256);
                                URL.revokeObjectURL(img.src);

                                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                                const data = imageData.data;

                                for (let i = 0; i < data.length; i += 4) {
                                    const value = data[i];
                                    const color = colorScaleWV[value];
                                    data[i] = color[0]; // Red
                                    data[i + 1] = color[1]; // Green
                                    data[i + 2] = color[2]; // Blue

                                    // If the original pixel was transparent, keep it transparent
                                    if (data[i + 3] === 0) {
                                        data[i + 3] = 0; // Fully transparent
                                    } else {
                                        data[i + 3] = 255; // Fully opaque
                                    }
                                }

                                ctx.putImageData(imageData, 0, 0);
                                canvas.toBlob(newBlob => {
                                    newBlob.arrayBuffer().then(arr => {
                                        resolve({ data: arr });
                                    });
                                });
                            };
                        });
                    });
            } else {
                return Promise.reject(new Error(`Tile fetch error: ${t.statusText}`));
            }
        });
});

addProtocol('enhancedIR', (params) => {
    return fetch(params.url.replace('enhancedIR://', ''))
        .then(t => {
            if (t.status === 200) {
                return t.blob()
                    .then(blob => {
                        const img = new Image();
                        img.src = URL.createObjectURL(blob);

                        return new Promise((resolve) => {
                            img.onload = function () {
                                // Clear the canvas and make it fully transparent
                                ctx.clearRect(0, 0, canvas.width, canvas.height);
                                ctx.fillStyle = "rgba(0, 0, 0, 0)";
                                ctx.fillRect(0, 0, canvas.width, canvas.height);

                                ctx.drawImage(img, 0, 0, 256, 256);
                                URL.revokeObjectURL(img.src);

                                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                                const data = imageData.data;

                                for (let i = 0; i < data.length; i += 4) {
                                    const value = data[i];
                                    const color = colorScaleWV[value];
                                    data[i] = color[0]; // Red
                                    data[i + 1] = color[1]; // Green
                                    data[i + 2] = color[2]; // Blue

                                    // If the original pixel was transparent, keep it transparent
                                    if (data[i + 3] === 0) {
                                        data[i + 3] = 0; // Fully transparent
                                    } else {
                                        data[i + 3] = 255; // Fully opaque
                                    }
                                }

                                ctx.putImageData(imageData, 0, 0);
                                canvas.toBlob(newBlob => {
                                    newBlob.arrayBuffer().then(arr => {
                                        resolve({ data: arr });
                                    });
                                });
                            };
                        });
                    });
            } else {
                return Promise.reject(new Error(`Tile fetch error: ${t.statusText}`));
            }
        });
});

export const generateSatelliteSource = map => {
    const ir108 = map.addSource('ir108', {
        'type': 'raster',
        'tiles': [`https://view.eumetsat.int/geoserver/ows?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&version=1.3.0&layers=msg_fes:rgb_airmass&styles=&idofrequest=${uuidv4()}&transparent=true&format=image/png&crs=EPSG:3857&width=256&height=256`],
        'tileSize': 256,
        'transparent': 'true',
        'id': uuidv4()
    });

    const wv062 = map.addSource('irWv', {
        'type': 'raster',
        'tiles': [`https://view.eumetsat.int/geoserver/ows?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&version=1.3.0&layers=msg_fes:wv062&styles=&idofrequest=${uuidv4()}&transparent=true&format=image/png&crs=EPSG:3857&width=256&height=256`],
        'tileSize': 256,
        'transparent': 'true',
        'id': uuidv4()
    });

    const vis = map.addSource('satVis', {
        'type': 'raster',
        'tiles': [`https://view.eumetsat.int/geoserver/ows?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&version=1.3.0&layers=msg_fes:rgb_eview&styles=&idofrequest=${uuidv4()}&transparent=true&format=image/png&crs=EPSG:3857&width=256&height=256`],
        'tileSize': 256,
        'transparent': 'true',
        'id': uuidv4()
    });
}

