import {
    clusterLayer,
    clusterLayerContours,
    createLightningHistoryLayer,
    liveLightningMarkers
} from "../layerstyle/layerstyle";

export const addLightningLayers = (map, dayNight) => {

    map.addLayer(clusterLayer, 'gpmlabel');
    map.addLayer(clusterLayerContours, 'clusterLayer');

    map.addLayer(createLightningHistoryLayer(dayNight));
    map.addLayer(liveLightningMarkers);


    // map.addLayer({
    //     'id':'extrapolationPolys',
    //     'type':'fill',
    //     'source':'extrapolationPolygons',
    //     'filter':["==", "$type", "Polygon"],
    //     'paint': {
    //         'fill-color': {
    //             property: 'count',
    //             'stops': [
    //                 [1, 'rgba(255,255,0,1)'],
    //                 [250, 'rgba(255,126,0,1)'],
    //                 [500, 'rgba(240,15,15,1)'],
    //                 [1000, 'rgba(255,0,255,1)'],
    //                 [1500, 'rgb(255,140,255)'],
    //             ]
    //         },
    //         'fill-opacity':0.1
    //     },
    //     'layout': {
    //         'visibility': window.localStorage.getItem('polylineVisibility')
    //             ? window.localStorage.getItem('polylineVisibility')
    //             : 'visible'
    //     }
    //
    // },'clusterLayer')
    // map.addLayer({
    //     'id':'extrapolationPolylines',
    //     'type':'line',
    //     'source':'extrapolationPolygons',
    //     'filter':["==", "$type", "Polygon"],
    //     'paint': {
    //         'line-color': {
    //             property: 'count',
    //             'stops': [
    //                 [1, 'rgba(255,255,0,1)'],
    //                 [250, 'rgba(255,126,0,1)'],
    //                 [500, 'rgba(240,15,15,1)'],
    //                 [1000, 'rgba(255,0,255,1)'],
    //                 [1500, 'rgb(255,140,255)'],
    //             ]
    //         },
    //         'line-opacity': 0.1,
    //         'line-width': 3,
    //         'line-dasharray': [3, 1],
    //     },
    //     'layout': {
    //         'visibility': window.localStorage.getItem('polylineVisibility')
    //             ? window.localStorage.getItem('polylineVisibility')
    //             : 'visible'
    //     }
    //
    // },'clusterLayer')
    // map.addLayer({
    //     'id':'pizza-label-line',
    //     'type':'line',
    //     'filter':["==", "$type", "LineString"],
    //     'source':'extrapolationPolygons',
    //     'paint':{
    //         'line-color': {
    //             property: 'count',
    //             stops: [
    //                 [1, 'rgba(255,255,0,1)'],
    //                 [250, 'rgba(255,126,0,1)'],
    //                 [500, 'rgba(240,15,15,1)'],
    //                 [1000, 'rgba(255,0,255,1)'],
    //                 [1500, 'rgb(255,140,255)'],
    //             ]
    //         },
    //         'line-opacity':0.75,
    //         'line-width':2,
    //         'line-dasharray':[1,3]
    //     },
    //     'layout':{
    //     }
    // },'clusterLayer')
    // map.addLayer({
    //     'id':'pizza-label-circles',
    //     'type':'circle',
    //     'filter':["==", "$type", "Point"],
    //     'source':'extrapolationPolygons',
    //     'minzoom': 5,
    //     'paint':{
    //         'circle-color': {
    //             property: 'count',
    //             stops: [
    //                 [1, 'rgba(255,255,0,1)'],
    //                 [250, 'rgba(255,126,0,1)'],
    //                 [500, 'rgba(240,15,15,1)'],
    //                 [1000, 'rgba(255,0,255,1)'],
    //                 [1500, 'rgb(255,140,255)'],
    //             ]
    //         },
    //         'circle-radius': {
    //             stops: [[6, 4], [8, 6], [11, 12], [12, 20], [14, 30]]
    //         },
    //         'circle-opacity':0.2
    //     },
    //     'layout':{
    //     }
    // },'clusterLayer')
    // map.addLayer({
    //     'id':'pizza-labels',
    //     'type':'symbol',
    //     'filter':["==", "$type", "Point"],
    //     'source':'extrapolationPolygons',
    //     'minzoom': 7,
    //     "paint": {
    //         "text-color": 'rgb(0,0,0)', //Color of your choice
    //         "text-halo-blur": 1,
    //         "text-halo-color": 'rgb(255,255,255)',
    //         "text-halo-width": 1,
    //         "text-opacity": 0.65
    //     },
    //     "layout": {
    //         "text-field": ['concat', ['to-string', ['get', 'time']], ''], //This will get "time" property from your geojson
    //         "text-font": ['Tahoma, sans-serif'],
    //         "text-rotation-alignment": "auto",
    //         "text-allow-overlap": true,
    //         "text-anchor": "center",
    //         "text-size": {
    //             stops: [[6, 6], [8, 12], [11, 15], [12, 20], [14, 30]]
    //         }
    //     }
    // },'clusterLayer')




    // map.addLayer(innerClusterLayerContours, 'clusterLayerLines');

    //map.addLayer(extrapolationLayerPolys, 'clusterLayer');
    // map.addLayer({
    //     'id': 'clusterCenterpointLayer',
    //     'type': 'circle',
    //     'source': 'cluster_centerpoints',
    //     'paint': {
    //         'circle-color': 'rgba(255,0,0,1)' //0,217,255,0.5
    //     }
    // });

}
