var _pyfunc_list = function (x) {
    var r=[];
    if (typeof x==="object" && !Array.isArray(x)) {x = Object.keys(x);}

    for (var i=0; i<x.length; i++) {
        r.push(x[i]);
    }
    return r;
};
var _pyfunc_op_add = function (a, b) { // nargs: 2
    if (Array.isArray(a) && Array.isArray(b)) {
        return a.concat(b);
    } return a + b;
};
var _pymeth_append = function (x) { // nargs: 1
    if (!Array.isArray(this)) return this.append.apply(this, arguments);
    this.push(x);
};
var _pymeth_get = function (key, d) { // nargs: 1 2
    if (this.constructor !== Object) return this.get.apply(this, arguments);
    if (this[key] !== undefined) {return this[key];}
    else if (d !== undefined) {return d;}
    else {return null;}
};
var _pymeth_join = function (x) { // nargs: 1
    if (this.constructor !== String) return this.join.apply(this, arguments);
    return x.join(this);  // call join on the list instead of the string.
};
export const decode = function flx_decode (b) {
    var a, c, d, e, f, g, h, o;
    e = ({});
    d = _pyfunc_list(b);
    c = d[0];
    f = c;
    g = [c];
    h = 256;
    o = h;
    for (b = 1; b < d.length; b += 1) {
        a = d[b].charCodeAt(0);
        a = ((h > a))? (d[b]) : (_pymeth_get.call(e, a, _pyfunc_op_add(f, c)));
        _pymeth_append.call(g, a);
        c = a[0];
        e[o] = _pyfunc_op_add(f, c);
        o += 1;
        f = a;
    }
    return _pymeth_join.call("", g);
};

