import axios from "axios";

export const generateTooltip = async (info, coordinates) => {

    const addressData = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${coordinates[1]}&lon=${coordinates[0]}&format=json`);

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const generateDescription = () => {

        // console.log(info);

        if(info.description && !isJsonString(info.description)) { return (`<p>${info.description}</p>`); }
        else { return ('No description'); }
    };

    const generateImage = () => {
        if(info.image_m) {
            return (
                `<img src="https://img.locationscout.net/images/${info.image_m}"  alt="${info.name}" width="220"/>`
            );
        } else { return ('No image'); }
    };

    const generateAddress = async () => {
        const geolocation = await addressData;
        // console.log(geolocation.data.address);
        const { country, municipality, city, village, road, town, postcode, house_number } = geolocation.data.address;

        const generateCity = () => {
            return `${postcode ? postcode : ''} ${city ? city : ''} ${village ? village : ''} ${town ? town : ''} ${municipality ? municipality : ''}`;
        };

        const generateRoad = () => {
            return `${road ? road : ''} ${house_number ? house_number : ''}`;
        };

        return (
            `
                <div>
                    <div><strong>City: </strong>${generateCity()}</div>
                    <div><strong>Road: </strong>${generateRoad()}</div>
                    <div><strong>Country: </strong>${country}</div>
                </div>
                
            `
        );
    };

    const generateWaze = () => `https://waze.com/ul?ll=${coordinates[1]},${coordinates[0]}&z=10&navigate=yes`;

    return (
        `
            <div class="tooltip">
                <h3>${info.Name}</h3>
                <div>${generateDescription()}</div>
                <div>${generateImage()}</div>
                <div class="tooltip-location">
                    ${await generateAddress()}
                    <div class="address-waze">
                        <a href="${generateWaze()}">
                            <img src="https://weatherportal.eu/assets/images/location.png" height="30px">
                        </a>
                    </div>
                </div>
            </div>
        `
    );
};