export const calculateTimeDiffForColorization = (timeDiff) => {

    let roundedTimeDiff = 0;

    if (timeDiff >= 120 * 60000) {
        roundedTimeDiff = "120";
    } else if (timeDiff >= 100 * 60000) {
        roundedTimeDiff = "100";
    } else if (timeDiff >= 80 * 60000) {
        roundedTimeDiff = "80";
    } else if (timeDiff >= 60 * 60000) {
        roundedTimeDiff = "60";
    } else if (timeDiff >= 40 * 60000) {
        roundedTimeDiff = "40";
    } else if (timeDiff >= 20 * 60000) {
        roundedTimeDiff = "20";
    }

    return roundedTimeDiff;
};