import axios from 'axios';

// Get the spinner element
const spinner = document.getElementById('spinner');

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Show spinner before request is sent
    spinner.style.display = 'block';
    return config;
}, function (error) {
    // Hide spinner if request error occurs
    spinner.style.display = 'none';
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Hide spinner when response is received
    spinner.style.display = 'none';
    return response;
}, function (error) {
    // Hide spinner if response error occurs
    spinner.style.display = 'none';
    return Promise.reject(error);
});

export default axios;